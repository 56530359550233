import moment from "moment";
import ApiService from "../services/ApiService";
interface DeliveryChallan {
  id: number;
  challan_number: string;
  date: Date;
  place_of_supply: string;
  total_amt: string;
  grand_total: string;
  round_off_amt: string;
  party: string;
  party_data: any;
  created_by: string;
}

const CancelToken = ApiService.getInstance().CancelToken;
let cancelRequest;
const DeliveryChallans: Array<DeliveryChallan> = [];

const deleteDeliveryChallan = function (item: DeliveryChallan) {
  return ApiService.delete(`delivery-challan/${item.id}/`);
};

const addDeliveryChallan = function (item, file, id) {
  const formData = new FormData();
  delete item.image;
  const dateFieldKey: any = ["date"];
  for (const key in item) {
    if (dateFieldKey.includes(key)) {
      formData.append(key, moment(item[key]).format("YYYY-MM-DD"));
    } else {
      formData.append(key, item[key]);
    }
  }
  if (file && file[0]) {
    formData.append("image", file[0]);
  }
  if (id) {
    return ApiService.put(`delivery-challan/${id}/`, formData);
  } else {
    return ApiService.post("delivery-challan/", formData);
  }
};

const exportDeliveryChallanData = function (ids) {
  return new Promise((res, rej) => {
    ApiService.csvFile(
      "export/delivery-challan/?deliveryChallan_id=" + ids,
      "deliveryChallan-data"
    ).then((data) => res(data));
  });
};
const exportPDFDeliveryChallans = function (ids) {
  return new Promise((res, rej) => {
    ApiService.pdfFile("pdf/delivery-challan/?deliveryChallan_id=" + ids).then(
      (data) => res(data)
    );
  });
};
const deliveryChallanObjClone = function () {
  return {
    id: 0,
    challan_number: `UC23-24/`,
    date: new Date(),
    place_of_supply: "",
    total_amt: "0",
    grand_total: "0",
    round_off_amt: "0",
    party: "",
    party_data: {},
    created_by: "",
  };
};

const jsonToInterface = function (item: any) {
  const newItem: DeliveryChallan = deliveryChallanObjClone();
  newItem.id = item.id;
  newItem.challan_number = item.challan_number;
  newItem.date = moment(item.date).toDate();
  newItem.place_of_supply = item.place_of_supply;
  newItem.total_amt = item.total_amt;
  newItem.total_amt = item.total_amt;
  newItem.grand_total = item.grand_total;
  newItem.round_off_amt = item.round_off_amt;
  newItem.party = item.party;
  newItem.party_data = item.party_data;
  newItem.created_by = item.created_by;
  return newItem;
};
const getDeliveryChallanById = function (id) {
  return new Promise((res, rej) => {
    ApiService.get(`delivery-challan/${id}`).then(function (response) {
      res(jsonToInterface(response.data));
    });
  });
};

const getDeliveryChallans = function (
  pageNumber = 1,
  searchData,
  status = "",
  user = ""
) {
  return new Promise((res, rej) => {
    const limit = 10;
    const queryObj = {
      page: pageNumber,
      limit: limit,
      offset: (pageNumber - 1) * limit,
      search: searchData.name || "",
      party: searchData.party_id || "",
      status: status,
      created_by: user,
    };
    console.log(queryObj);
    const queryStr = Object.keys(queryObj)
      .filter((key) => queryObj[key] != "")
      .map((key) => key + "=" + queryObj[key])
      .join("&");

    if (typeof cancelRequest != typeof undefined) {
      cancelRequest.cancel("Operation canceled due to new request.");
    }
    cancelRequest = CancelToken.source();
    ApiService.getWithoutSlash(`delivery-challan/?${queryStr}`, cancelRequest)
      .then(function (response) {
        const resObj: any = {
          count: 0,
          data: [],
        };
        resObj.count = response.data.count;
        if (response.data && response.data.results) {
          resObj.data = response.data.results.map((item) => {
            return jsonToInterface(item);
          });
        }
        console.log("resObj", resObj);
        res(resObj);
      })
      .catch((error) => rej(error));
  });
};

const getDeliveryChallanCuttingsCount = function (
  status = "",
  deliveryChallan_id = "",
  design_material_item_id = ""
) {
  return new Promise((res, rej) => {
    const queryObj = {
      deliveryChallan_id: deliveryChallan_id,
      design_material_item_id: design_material_item_id,
    };
    console.log(queryObj);
    const queryStr = Object.keys(queryObj)
      .filter((key) => queryObj[key] != "")
      .map((key) => key + "=" + queryObj[key])
      .join("&");

    if (typeof cancelRequest != typeof undefined) {
      cancelRequest.cancel("Operation canceled due to new request.");
    }
    cancelRequest = CancelToken.source();
    ApiService.getWithoutSlash(
      `delivery-challan/count/?${queryStr}`,
      cancelRequest
    )
      .then(function (response) {
        res(response.data);
      })
      .catch((error) => rej(error));
  });
};
const jsonToInterfaceDeliveryChallan = jsonToInterface;
const getEmptyObjOfDeliveryChallan = deliveryChallanObjClone;
export {
  DeliveryChallan,
  getDeliveryChallans,
  deleteDeliveryChallan,
  addDeliveryChallan,
  getDeliveryChallanById,
  jsonToInterfaceDeliveryChallan,
  deliveryChallanObjClone,
  exportDeliveryChallanData,
  exportPDFDeliveryChallans,
  getEmptyObjOfDeliveryChallan,
  getDeliveryChallanCuttingsCount,
};

export default DeliveryChallans;
